
const mediaLinks = [
  {
    name: "Facebook - Aus",
    link: "https://www.facebook.com/LinkInternationalEducationAgency/",
  },
  {
    name: "Facebook - Col",
    link: "https://www.facebook.com/LinkInternationalEducationAgencyColombia/",
  },
  {
    name: "Instagram - Aus",
    link: "https://www.instagram.com/linkinternationalagencyau/",
  },
  {
    name: "Instagram - Col",
    link: "https://www.instagram.com/link.international/",
  },
];


export default mediaLinks;