import React from "react";
import { BsArrowRight } from "react-icons/bs";
import ProfileForm from "components/ProfileForm/ProfileForm";
import Modal from "components/Modal/Modal";
import useModal from "../../hooks/useModal";
import "./Contact.css";
import ProfileResutl from "components/ProfileResult/ProfileResult";

const Contact = () => {
  const [isOpen, openModal, closeModal] = useModal(false);
  const [isOpenTest, openModalTest, closeModalTest] = useModal(false);

  const handleModal = (e) => {
    closeModal();
    openModalTest();
  };

  const handleOtherModal = (e) => {
    closeModalTest();
    window.location.href = "/";
  };

  return (
    <div className="app__contact" id="contact">
      <div className="app__contact-content">
        <p>¡llegaste a un punto muy importante, felicidades!</p>
        <h3>inicia el tramite de tu futuro hoy</h3>
        <p>
          Conocer nuevas culturas, dominar un segundo idioma, crecer a nivel
          profesional y personal, cumplir tu sueño.
        </p>
        <div>
          <button type="button" className="app__ctaButton" onClick={openModal}>
            ¡Agenda tu cita!
            <span>
              <BsArrowRight fontSize={22} />
            </span>
          </button>
        </div>
      </div>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <ProfileForm handleModal={handleModal} />
      </Modal>
      <Modal isOpen={isOpenTest} closeModal={closeModalTest}>
        <ProfileResutl handleOtherModal={handleOtherModal} />
      </Modal>
    </div>
  );
};

export default Contact;
