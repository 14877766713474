import React, { useEffect, useState, useRef} from 'react';
import './Statistics.css';
import CountUp from 'react-countup';

const Statistics = () => {

  const [ show, setShow ] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const onChange = (entries, observer) => {
      const elem = entries[0];
      if (elem.isIntersecting) {
          setShow(true);
          observer.disconnect();
      }
    }

    const observer = new IntersectionObserver(onChange, {
      rootMargin: '100px'
    });

    observer.observe(ref.current);

  }, []);

  const sectionStatistics = () => {

    return (
      <>
        <div className='app__statistics-content'>
      <div className='app__statistics-flex'>
        <div className='app__statistics-flex-data' data-aos='fade-right'>
          <div>
            <h4>Somos una agencia que brinda asesoría integra y profesional.</h4>
            <p> Caminamos de la mano contigo en todo. Una vez en tu destino, te ayudamos con que necesitas para comenzar a trabajar, extender tu visa o cualquier requerimiento específico.</p>
          </div>

          <div>
            <div>
              <h4>
                <CountUp end={7000} redraw={true} duration={4}/>+
              </h4>
              <p>Visas que obtuvieron aprobación.</p>
            </div>
            <div>
              <h4><CountUp end={7} redraw={true} duration={4}/>+</h4>
              <p>Paises para tu elección.</p>
            </div>
            <div>
              <h4><CountUp end={95} redraw={true} duration={4}/>%</h4>
              <p>En efectividad de aprovación de visas.</p>
            </div>
          </div>
        </div>

        <div className='app__statistics-flex-image'>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
      </>
    )
  }

  return (
  <div className='app__statistics' ref={ref} id='us'>
    {show ? sectionStatistics() : null}
  </div>
)};

export default Statistics;
