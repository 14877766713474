import React, { useEffect, useState, useRef } from 'react';
import './Country.css';

function Country ({ title, subtitle, id, srcImg, imgCopy }){

  const [ show, setShow ] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const onChange = (entries, observer) => {
      const elem = entries[0];
      if (elem.isIntersecting) {
        setShow(true);
        observer.disconnect();
      }
    }

    const observer = new IntersectionObserver(onChange, {
      rootMargin: '100px'
    });

    observer.observe(ref.current);

  }, []);

  const sectionCountry = () => {
    return (
      <>
        <div className='app__country-content'>
          <h1>
            {title}
          </h1>
          <p>
            {subtitle}
          </p>
          <p>
            {imgCopy}
          </p>
        </div>

        <div className='image__container app__country-image'>
          <div style={{backgroundImage : `url(${srcImg})`}}></div>
        </div>
      </>
    )
  }
  return(
    <div className='app__country' ref={ref} id={id}>
      {show ? sectionCountry() : null}
    </div>
  );
}

export default Country;
