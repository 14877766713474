const profileTestFormData = [
  {
    id: 1,
    name: "Solvencia Económica",
    created_at: "2022-06-16T22:39:40.023Z",
    evaluationOptions: [
      {
        id: 51,
        name: "Resuelta - familiar con 1° ó 2° de consanguineidad",
        value: 1,
        evlaTypeId: 1,
        created_at: "2022-06-17T00:48:45.568Z",
      },
      {
        id: 55,
        name: "No está clara",
        value: 0.1,
        evlaTypeId: 1,
        created_at: "2022-06-17T00:51:45.743Z",
      },
      {
        id: 54,
        name: "Pendiente Tiempo - familiar NO cumple con grado de consaguineidad",
        value: 0.75,
        evlaTypeId: 1,
        created_at: "2022-06-17T00:49:32.631Z",
      },
      {
        id: 53,
        name: "Pendiente Tiempo - familiar con 1° ó 2° de consanguineidad",
        value: 0.95,
        evlaTypeId: 1,
        created_at: "2022-06-17T00:49:32.629Z",
      },
      {
        id: 52,
        name: "Resuelta - familiar NO cumple con grado de consaguineidad",
        value: 0.76,
        evlaTypeId: 1,
        created_at: "2022-06-17T00:48:45.568Z",
      },
    ],
  },
  {
    id: 2,
    name: "Origen de los Dineros",
    created_at: "2022-06-16T22:43:15.882Z",
    evaluationOptions: [
      {
        id: 44,
        name: "Crédito en Trámite",
        value: 0.75,
        evlaTypeId: 2,
        created_at: "2022-06-17T00:38:01.464Z",
      },
      {
        id: 46,
        name: "Crédito Desembolsado",
        value: 1,
        evlaTypeId: 2,
        created_at: "2022-06-17T00:40:26.724Z",
      },
      {
        id: 45,
        name: "Crédito Aprobado",
        value: 0.98,
        evlaTypeId: 2,
        created_at: "2022-06-17T00:38:01.465Z",
      },
      {
        id: 48,
        name: "Sin justificación",
        value: 0.2,
        evlaTypeId: 2,
        created_at: "2022-06-17T00:46:12.646Z",
      },
      {
        id: 47,
        name: "Recursos propios Justificados",
        value: 0.99,
        evlaTypeId: 2,
        created_at: "2022-06-17T00:40:26.725Z",
      },
    ],
  },
  {
    id: 3,
    name: "Pasado Judicial",
    created_at: "2022-06-16T22:43:26.694Z",
    evaluationOptions: [
      {
        id: 50,
        name: "Con antecedentes",
        value: 0.2,
        evlaTypeId: 3,
        created_at: "2022-06-17T00:47:09.859Z",
      },
      {
        id: 49,
        name: "Sin antecedentes",
        value: 1,
        evlaTypeId: 3,
        created_at: "2022-06-17T00:47:09.859Z",
      },
    ],
  },
  {
    id: 4,
    name: "Empleado o Estudiante Actual en el momento del viaje",
    created_at: "2022-06-16T22:43:42.307Z",
    evaluationOptions: [
      {
        id: 39,
        name: "Empleado",
        value: 0.8,
        evlaTypeId: 4,
        created_at: "2022-06-16T23:56:57.877Z",
      },
      {
        id: 41,
        name: "Ninguno",
        value: 0,
        evlaTypeId: 4,
        created_at: "2022-06-17T00:12:40.777Z",
      },
      {
        id: 40,
        name: "Ambos",
        value: 1,
        evlaTypeId: 4,
        created_at: "2022-06-17T00:12:40.776Z",
      },
      {
        id: 38,
        name: "Estudiante",
        value: 1,
        evlaTypeId: 4,
        created_at: "2022-06-16T23:56:57.875Z",
      },
    ],
  },
  {
    id: 5,
    name: "Nivel de Estudios / Edad",
    created_at: "2022-06-16T22:43:53.415Z",
    evaluationOptions: [
      {
        id: 33,
        name: "Maestría - Entre 30 y 44 años",
        value: 0.7,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:43:15.494Z",
      },
      {
        id: 21,
        name: "Primaria - Hasta 18 años",
        value: 0.9,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:27:45.634Z",
      },
      {
        id: 22,
        name: "Primaria - Más de 18 años",
        value: 0.1,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:27:45.635Z",
      },
      {
        id: 23,
        name: "Bachillerato - Entre 18 y 29 años",
        value: 0.92,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:31:52.216Z",
      },
      {
        id: 24,
        name: "Bachillerato - Entre 30 y 44 años",
        value: 0.2,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:31:52.216Z",
      },
      {
        id: 25,
        name: "Bachillerato - Más de 45 años",
        value: 0,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:32:56.058Z",
      },
      {
        id: 26,
        name: "Técnico - Tecnólogo - Entre 18 y 29 años",
        value: 0.97,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:32:56.059Z",
      },
      {
        id: 27,
        name: "Técnico - Tecnólogo - Entre 30 y 44 años",
        value: 0.6,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:35:05.500Z",
      },
      {
        id: 28,
        name: "Técnico - Tecnólogo - Más de 44 años",
        value: 0.3,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:35:05.501Z",
      },
      {
        id: 29,
        name: "Profesional - Entre 18 y 29 años",
        value: 1,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:40:06.975Z",
      },
      {
        id: 30,
        name: "Profesional - Entre 30 y 44 años",
        value: 0.61,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:40:06.976Z",
      },
      {
        id: 31,
        name: "Profesional - Más de 44 años",
        value: 0.4,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:41:57.974Z",
      },
      {
        id: 32,
        name: "Maestría - Entre 18 y 29 años",
        value: 1.1,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:41:57.975Z",
      },
      {
        id: 34,
        name: "Maestría - Más de 44 años",
        value: 0.5,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:43:15.495Z",
      },
      {
        id: 35,
        name: "Doctorado - Entre 18 y 29 años",
        value: 1.3,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:45:43.053Z",
      },
      {
        id: 36,
        name: "Doctorado - Entre 30 y 44 años",
        value: 0.8,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:45:43.054Z",
      },
      {
        id: 37,
        name: "Doctorado - Más de 44 años",
        value: 0.63,
        evlaTypeId: 5,
        created_at: "2022-06-16T23:52:07.720Z",
      },
    ],
  },
  {
    id: 6,
    name: "Estado Civil",
    created_at: "2022-06-16T22:44:03.375Z",
    evaluationOptions: [
      {
        id: 20,
        name: "Nunca Casado ni en una Relación",
        value: 0.5,
        evlaTypeId: 6,
        created_at: "2022-06-16T23:18:02.485Z",
      },
      {
        id: 17,
        name: "Unión Libre",
        value: 0.9,
        evlaTypeId: 6,
        created_at: "2022-06-16T23:17:18.087Z",
      },
      {
        id: 16,
        name: "Viudo",
        value: 0.5,
        evlaTypeId: 6,
        created_at: "2022-06-16T23:17:05.260Z",
      },
      {
        id: 15,
        name: "Casado",
        value: 1,
        evlaTypeId: 6,
        created_at: "2022-06-16T23:16:52.084Z",
      },
      {
        id: 18,
        name: "Divorciado",
        value: 0.51,
        evlaTypeId: 6,
        created_at: "2022-06-16T23:17:32.523Z",
      },
      {
        id: 19,
        name: "Comprometido",
        value: 0.8,
        evlaTypeId: 6,
        created_at: "2022-06-16T23:17:50.291Z",
      },
    ],
  },
  {
    id: 7,
    name: "Situación Grupo Familiar",
    created_at: "2022-06-16T22:44:13.340Z",
    evaluationOptions: [
      {
        id: 14,
        name: "No tiene Grupo Familiar",
        value: 0.5,
        evlaTypeId: 7,
        created_at: "2022-06-16T23:15:11.720Z",
      },
      {
        id: 12,
        name: "Viene con Familia - Pareja Sin Estudios - Sin Ocupación",
        value: 0.3,
        evlaTypeId: 7,
        created_at: "2022-06-16T23:14:40.839Z",
      },
      {
        id: 11,
        name: "Viene con Familia - Pareja Con Estudios - Sin Ocupación",
        value: 0.8,
        evlaTypeId: 7,
        created_at: "2022-06-16T23:14:15.463Z",
      },
      {
        id: 10,
        name: "Viene con Familia - Pareja Estudiante o Empleado Formal",
        value: 0.9,
        evlaTypeId: 7,
        created_at: "2022-06-16T23:13:57.677Z",
      },
      {
        id: 13,
        name: "Viene SIN Familia",
        value: 1,
        evlaTypeId: 7,
        created_at: "2022-06-16T23:14:57.061Z",
      },
    ],
  },
  {
    id: 8,
    name: "Familiares en Australia",
    created_at: "2022-06-16T22:44:27.766Z",
    evaluationOptions: [
      {
        id: 5,
        name: "Si - Permanente. Llegó como Permanente",
        value: 0.9,
        evlaTypeId: 8,
        created_at: "2022-06-16T22:59:35.918Z",
      },
      {
        id: 4,
        name: "Si - Permanente. Llegó como Temporal",
        value: 0.2,
        evlaTypeId: 8,
        created_at: "2022-06-16T22:59:18.111Z",
      },
      {
        id: 3,
        name: "Si - Temporal",
        value: 0.5,
        evlaTypeId: 8,
        created_at: "2022-06-16T22:58:31.494Z",
      },
      {
        id: 7,
        name: "Si - Ciudadano. Llegó como Permanente",
        value: 1,
        evlaTypeId: 8,
        created_at: "2022-06-16T23:00:21.076Z",
      },
      {
        id: 6,
        name: "Si - Ciudadano. Llegó como Temporal",
        value: 0.21,
        evlaTypeId: 8,
        created_at: "2022-06-16T22:59:57.286Z",
      },
      {
        id: 9,
        name: "No tiene familiares en Australia",
        value: 0.5,
        evlaTypeId: 8,
        created_at: "2022-06-16T23:00:52.406Z",
      },
      {
        id: 8,
        name: "Siempre Ciudadano",
        value: 1,
        evlaTypeId: 8,
        created_at: "2022-06-16T23:00:30.720Z",
      },
    ],
  },
  {
    id: 9,
    name: "Enfermedades",
    created_at: "2022-06-16T22:44:33.091Z",
    evaluationOptions: [
      {
        id: 2,
        name: "Si",
        value: 0.1,
        evlaTypeId: 9,
        created_at: "2022-06-16T22:57:27.612Z",
      },
      {
        id: 1,
        name: "No",
        value: 1,
        evlaTypeId: 9,
        created_at: "2022-06-16T22:57:12.449Z",
      },
    ],
  },
];

const profileTypeData = [
  {
    id: 1,
    name: "Perfil Indicado",
    valueBegin: 75,
    valueEnd: 100,
    process: true,
    createdAt: "2022-06-17T06:09:33.038Z",
  },
  {
    id: 2,
    name: "Perfil Aceptable ",
    valueBegin: 50,
    valueEnd: 75,
    process: true,
    createdAt: "2022-06-17T06:12:44.565Z",
  },
  {
    id: 3,
    name: "Perfil con Limitaciones",
    valueBegin: 25,
    valueEnd: 50,
    process: true,
    createdAt: "2022-06-17T06:12:44.565Z",
  },
  {
    id: 4,
    name: "Perfil no Apto",
    valueBegin: 0,
    valueEnd: 25,
    process: false,
    createdAt: "2022-06-17T06:12:44.566Z",
  },
];

export { profileTestFormData, profileTypeData}