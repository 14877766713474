import logoSvg from 'assets/logo2.svg';
import australia from 'assets/australia.png';
import unitedStates from 'assets/estados-unidos.png';
import canada from 'assets/canada.png';
import newZeland from 'assets/nueva-zelanda.png';
import england from 'assets/reino-unido.png';
import malta from 'assets/malta.png';
import alemania from 'assets/alemania.png';
import australiaImg from 'assets/compressed_australia.jpg';
import unitedStatesImg from 'assets/compressed_united-states.jpg';
import canadaImg from 'assets/compressed_canada.jpg';
import newZelandImg from 'assets/compressed_new-zeland.jpg';
import englandImg from 'assets/compressed_england.jpg';
import maltaImg from 'assets/compressed_malta.jpg';
import alemaniaImg from 'assets/compressed_alemania.jpg';

const images = {
  logoSvg,
  australia,
  unitedStates,
  canada,
  newZeland,
  england,
  malta,
  alemania,
  australiaImg,
  unitedStatesImg,
  canadaImg,
  newZelandImg,
  englandImg,
  maltaImg,
  alemaniaImg,
};

export default images;