import React, { useEffect } from "react";
import "./Calendly.css";

function Calendly({ minWidth, height, url }) {
  useEffect(() => {
    const container = document.getElementById("ProfileResult");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    container.appendChild(script);
  }, []);
  return (
    <div
        className="calendly-inline-widget"
        data-url={url}
        style={{ minWidth, height}}
    />
  );
}

export default Calendly;
