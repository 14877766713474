import React, { useState, useEffect, useRef} from 'react';
import { HiOutlineLocationMarker } from "react-icons/hi";
import './FindUs.css';

const FindUs = () => {

  const [ show, setShow ] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const onChange = (entries, observer) => {
      const elem = entries[0];
      if (elem.isIntersecting) {
          setShow(true);
          observer.disconnect();
      }
    }

    const observer = new IntersectionObserver(onChange, {
      rootMargin: '100px'
    });

    observer.observe(ref.current);

  }, []);

  const sectionFindUs = () => {
    return (
      <>
        <div className="app__findUs-content">
          <div className="app__findUs-flex">
            <div className="app__findUs-flex-data" data-aos="fade-right">
              <div>
                <h4>Encuentranos...</h4>
                <p>
                  Te adjuntamos informacion de nuestras oficinas principales.
                </p>
              </div>

              <div>
                <div>
                  <h4>
                    <a
                      href="https://goo.gl/maps/gS1FJRHHnnKjfSwB7"
                      target="_blank"
                      rel="noreferrer"
                    >
                      bucaramanga <HiOutlineLocationMarker color="#ff0000" />
                    </a>
                  </h4>
                  <p>
                    Calle 36 #31-39 Oficina 227 Centro empresarial Chicamocha
                  </p>
                </div>
                <div>
                  <h4>
                    <a
                      href="https://goo.gl/maps/3CFaqQXrgWSs5Z98A"
                      target="_blank"
                      rel="noreferrer"
                    >
                      florida <HiOutlineLocationMarker color="#ff0000" />
                    </a>
                  </h4>
                  <p>Ruitoque Condominio Oficina 202 </p>
                </div>
                <div>
                  <h4>
                    <a
                      href="https://goo.gl/maps/xe4JVrd2cnvPaYxY8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cali <HiOutlineLocationMarker color="#ff0000" />
                    </a>
                  </h4>
                  <p>
                    Calle 100 #5 - 169 Oficina 313C Centro Comercial Unicentro
                  </p>
                </div>
                <div>
                  <h4>
                    <a
                      href="https://wa.me/573174043680?text=¡Hola!%20Estoy%20interesad@%20en%20viajar...%20¡Quiero%20agendar%20una%20cita!"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Oficina virtual <HiOutlineLocationMarker color='#ff0000'/>
                    </a>
                  </h4>
                  <p>
                    Si no estas cerca no te preocupes, también te brindamos la
                    mejor atención virtual.
                  </p>
                </div>
              </div>
            </div>

            <div className="app__findUs-flex-image">
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return(
    <div className='app__findUs' ref={ref} id='findUs'>
      {show ? sectionFindUs() : null}
    </div>
  );

}

export default FindUs;
