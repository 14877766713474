import React, { useEffect , useState} from "react";
import * as ReactDOM from "react-dom";
import "./Modal.css";

function Modal({ children, isOpen, closeModal }) {
  const [hijo, setHijo] = useState(children)

  useEffect(() => {
    setHijo(children)
  }, [children])

  const handleClickModal = (e) => {
    e.stopPropagation();
  };

  const jsx = (
    <div className={`Modal ${isOpen && "is-open"}`} onClick={closeModal}>
      <div className="ModalContainer" onClick={handleClickModal}>
        <button className="Modal-close" onClick={closeModal}>
          X
        </button>
        {hijo}
      </div>
    </div>
  );

  return ReactDOM.createPortal(jsx, document.getElementById("modal"));
}

export default Modal;
