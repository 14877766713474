import React, { useEffect } from "react";
import { images } from "../../constants";
import { MdExpandMore } from "react-icons/md";
import "./Header.css";

const textToType =
  "te conectamos con tu sueño de estudiar y vivir en el exterior.";

const Header = () => {
  const [typeEffect, setTypeEffect] = React.useState("");

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setTypeEffect(textToType.slice(0, typeEffect.length + 1));
    }, 50);
    return () => clearTimeout(timeOut);
  }, [typeEffect]);

  const goToSection = (section) => {
    window.location.href = `#${section.target.ariaLabel}`;
  };

  return (
    <div className="app__header" id="home">
      <div className="app__header_info">
        <div className="app__header-grid">
          <div className="app__header-grid-h1">
            <div>
              <h1>{typeEffect}</h1>
              <p>
                Cuentanos cuales son tus sueños y te ayudaremos a encontrar el
                lugar ideal para ti.
                <span> SOMOS TU ONE STOP</span> es decir, que te ayudamos a
                gestionar todo lo necesario para que puedas vivir y estudiar en
                el exterior.
              </p>
            </div>

            <div>
              <img
                src={images.unitedStates}
                alt="unitedStates_flag"
                title="estados unidos"
                aria-label="unitedStates"
                onClick={goToSection}
              />
              <img
                src={images.newZeland}
                alt="new_zeland_flag"
                title="nueva zelanda"
                aria-label="newZeland"
                onClick={goToSection}
              />
              <img
                src={images.canada}
                alt="canada_flag"
                title="canada"
                aria-label="canada"
                onClick={goToSection}
              />
              <img
                src={images.australia}
                alt="australia_flag"
                title="australia"
                aria-label="australia"
                onClick={goToSection}
              />
              <img
                src={images.malta}
                alt="malta_flag"
                title="malta"
                aria-label="malta"
                onClick={goToSection}
              />
              <img
                src={images.england}
                alt="england_flag"
                title="inglaterra"
                aria-label="england"
                onClick={goToSection}
              />
              <img
                src={images.alemania}
                alt="alemania_flag"
                title="alemania"
                aria-label="alemania"
                onClick={goToSection}
              />
            </div>
          </div>

          <div className="app__header-grid-scrollBtn">
            <a href="#australia">
              <MdExpandMore className="app__header-arrowIcon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
