import SocialLinks from 'components/SocialLinks/SocialLinks';
import React from 'react';
import { images, mediaLinks } from '../../constants';

import './Footer.css';

const Footer = () => (
  <div className="app__footer ">
    <div className="app__footer__content">
      <div className='app__footer__content-pivot'> 
        <div className='app__footer-flexContent'>
          <a className='app__footer-logoFooter' href='/'>
            <img src={images.logoSvg} alt="logo-footer" className='logoFooter'/>
          </a>
          <SocialLinks links={mediaLinks} />
        </div>
      </div>

      <div></div>
    </div>
  </div>
);

export default Footer;
