import React from 'react';
import { FiMap } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { images } from "../../constants/index"
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  return(
  <nav className="app__navbar">
    <div className='app__navbar-container'>
      <a href="/" title="link" className="app__navbar-logo">
        <img src={images.logoSvg} alt="app logo" className='imgLogo'/>
      </a>

      <ul className="app__navbar-links">
        <li>
          <a href="#home">Inicio</a>
        </li>
        <li>
          <a href="#us">Nosotros</a>
        </li>
        <li>
          <a href="#findUs">Encuentranos</a>
        </li>
        <li>
          <a href="#contact">Contacto</a>
        </li>
        <li>
          <a href="https://www.linkinternationalagency.co/" target='_blank' rel="noreferrer">Historia</a>
        </li>
      </ul>

      <div className="app__navbar-smallscreen">
        <AiOutlineMenu color='#ff0000' fontSize={30} onClick={() => setToggleMenu(true)}/>

        {
          toggleMenu && (
            <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
              <FiMap color='#ff0000' fontSize={30} className="overlay__close" onClick={() => setToggleMenu(false)} />
              <ul className="app__navbar-smallscreen_links">
                <li>
                  <a href="#home">Inicio</a>
                </li>
                <li>
                  <a href="#us">Nosotros</a>
                </li>
                <li>
                  <a href="#findUs">Encuentranos</a>
                </li>
                <li>
                  <a href="#contact">Contacto</a>
                </li>
                <li>
                  <a href="https://www.linkinternationalagency.co/" target='_blank' rel="noreferrer">Historia</a>
                </li>
              </ul>
            </div>
          )
        }
        
      </div>
    </div>
  </nav>
)
}
export default Navbar;
