import React from "react";
import { IconContext } from "react-icons/lib";
import { FiFacebook, FiInstagram, FiLink } from "react-icons/fi";
import "./SocialLinks.css";

const SocialLinks = ({ links }) => {
  const listItems = links.map((link, i) =>
    link.name.includes("Facebook") ? (
      <li key={`${link.name}-li}`} className="Brand">
        <a
          key={`${link.name}-a}`}
          href={link.link}
          target="_blank"
          rel="nonreferrer noreferrer"
        >
          <FiFacebook
            className="socialIcon"
            key={`${link.name}-i}`}
            fontSize={30}
            color="#ff0000"
          />
          {link.name}
        </a>
      </li>
    ) : (
      <li key={`${link.name}-li}`} className="Brand">
        <a
          key={`${link.name}-a}`}
          href={link.link}
          target="_blank"
          rel="nonreferrer noreferrer"
        >
          <FiInstagram
            className="socialIcon"
            key={`${link.name}-i`}
            fontSize={30}
            color="#ff0000"
          />
          {link.name}
        </a>
      </li>
    )
  );
  return (
    <div className="SocialLinks">
      <span className="SocialLinks-label">
        Social Links
        <IconContext.Provider value={{ className: "linkIcon" }}>
          <FiLink />
        </IconContext.Provider>
      </span>
      <ul className="Solid SocialLinks-items">{listItems}</ul>
    </div>
  );
};

export default SocialLinks;
