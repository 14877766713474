import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
//import axios from "axios";

import { testVars } from "../../constants/index";
import "./ProfileForm.css";


//const API = "http://localhost:3000/api/v1/profileTest/evaluationTypes";

function ProfileForm(props) {
  const [payload, setPayload] = useState({
    schema: null,
    inputs: null,
    options: null,
    testValue: null,
    renderForm: true,
  });

  const handleModal = props.handleModal;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = testVars.profileTestFormData;
        //await axios.get(API);

        const fieldsTmp = response.map((item, index) => {
          //response.data.map
          // eslint-disable-next-line no-unused-vars
          let { evaluationOptions, name, ...field } = item;
          return name;
        });

        const options = response.map((item, index) => {
          //response.data.map
          // eslint-disable-next-line no-unused-vars
          let { evaluationOptions, ...field } = item;
          return evaluationOptions;
        });

        const fields = fieldsTmp.reduce(
          (arr, val) => ({ ...arr, [val]: "" }),
          {}
        );

        const object = Yup.object().shape(
          fieldsTmp.reduce(
            (arr, val) => ({
              ...arr,
              [val]: Yup.string().required("Requerido"),
            }),
            {}
          )
        );

        setPayload({
          inputs: fields,
          options: options,
          schema: object,
        });
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [payload.renderForm]);

  const handleClick = (vals, { resetForm }) => {
    const totalTest = Math.round(
      Object.values(vals)
        .map((item) => Number(item))
        .reduce((acc, curr) => acc + curr) * 10
    );
    console.log(totalTest);
    localStorage.setItem("valueTest", totalTest);

    setPayload({ testValue: totalTest, renderForm: false });
    handleModal();
    resetForm({ values: { ...payload.inputs } });
  };

  return (
    <Formik
      initialValues={{ ...payload.inputs }}
      validationSchema={payload.schema}
      onSubmit={(values, { resetForm }) => handleClick(values, { resetForm })}
    >
      <Form className="Form-container">
        {payload.inputs && payload.options
          ? Object.keys(payload.inputs).map((item, index) => {
              const field = item;

              return (
                <div key={`field${index}`} className="Fiel-container">
                  <label className="Field-label">{item}</label>
                  <Field className="Field-field" name={item} as="select">
                    <option key={`option${index}`} value="">
                      --seleccione--
                    </option>
                    {payload.options[index].map((option, i) => (
                      <option
                        key={`option${i}-${option.name}`}
                        value={option.value}
                      >
                        {option.name}
                      </option>
                    ))}
                  </Field>
                  <div className="Field-error">
                    <ErrorMessage name={field} />
                  </div>
                </div>
              );
            })
          : null}

        {payload.inputs && payload.options && (
          <div className="Form-button__container">
            <button type="submit" className="Form-button">
              Enviar
            </button>
          </div>
        )}
      </Form>
    </Formik>
  );
}

export default ProfileForm;
