import React, { useEffect } from 'react';
import { FindUs, Footer, Header, Statistics } from './container';
import { Country, Navbar } from './components';
import { images } from './constants';
import AOS from 'aos';
import './App.css';
import Contact from './container/Contact/Contact';

function App() {

  useEffect(() => {
  AOS.init({
    duration : 2000
  });
  }, []);

  return (
    <div>
      <header>
        <Navbar />
      </header>
      <main>
        <Header />
        <Country
          title="Australia"
          subtitle="No hay NADA como Australia"
          id="australia"
          srcImg={images.australiaImg}
          //imgCopy="Foto de Mudassir Ali en Pexels"
        />
        <Country
          title="Estados Unidos"
          subtitle="Todo a tu alcance"
          id="unitedStates"
          srcImg={images.unitedStatesImg}
          //imgCopy="Foto de Vlad Alexandru Popa en Pexels"
        />
        <Statistics />
        <Country
          title="Canadá"
          subtitle="Sigue explorando"
          id="canada"
          srcImg={images.canadaImg}
          //imgCopy="Foto de Brayden Law en Pexels"
        />
        <Country
          title="Nueva Zelanda"
          subtitle="100% Puro"
          id="newZeland"
          srcImg={images.newZelandImg}
          //imgCopy="Foto de Liam Spicer en Pexels"
        />
        <FindUs />
        <Country
          title="Malta"
          subtitle="Siéntete libre de nuevo"
          id="malta"
          srcImg={images.maltaImg}
          //imgCopy="Foto de Som Thapa Magar en Pexels"
        />
        <Country
          title="Alemania"
          subtitle="Simplemente inspirador"
          id="alemania"
          srcImg={images.alemaniaImg}
          //imgCopy="Foto de Martha Sales en Pexels"
        />
        <Country
          title="Inglaterra"
          subtitle="Descubre todo lo que es posible"
          id="england"
          srcImg={images.englandImg}
          //imgCopy="Foto de Oleg Magni en Pexels"
        />
        <Contact />
        <hr className="mainLine" />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default App;
