import React, { useEffect, useState } from "react";
import { testVars } from "../../constants/index";
import Calendly from "components/Calendly/Calendly";
import "./ProfileResult.css";
//import axios from "axios";

//const API = "http://localhost:3000/api/v1/profileTest/profileType";
// async function fetchData() {
//   axios.get(`${API}/${value}`).then((response) => {
//     setProfileType(response.data[0]);
//   });
// }

function ProfileResutl(props) {
  const [profileType, setProfileType] = useState({});

  const handleModal = props.handleOtherModal;
  const valueTest = localStorage.getItem("valueTest");

  const filterProfileType = (val, item) => {
    if (( val >= item.valueBegin)  && (item.valueEnd > val)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = testVars.profileTypeData.filter(
          (item) =>(filterProfileType(valueTest, item))
        );

        setProfileType(response[0]);
      } catch (error) {
        console.error("Fetch error", error);
      }
    }
    fetchData();
  }, [valueTest]);

  return (
    <div id="ProfileResult" className="ProfileResult-container">
      <div
        className={`ProfileResult-container__header ${ profileType.process === true ? 'green' : 'red'}`}
      >
        {profileType.process ? <h2>Felicitaciones</h2> : <h2>Lo sentimos</h2>}
        {profileType.process ? (
          <p>Gracias por contactarnos y hacernos parte de tu sueño. Cumples con el PERFIL, agenda una cita con nosotros para continuar el proceso</p>
        ) : (
          <p>Gracias por contactarnos y hacernos parte de tu sueño. No cumples con el PERFIL , pero no te preocupes <a className="Contact-link"href='https://wa.me/573174043680?text=¡Hola!%20Estoy%20interesad@%20en%20viajar...%20¡Quiero%20agendar%20una%20cita!'>CONTÁCTANOS</a> para conocer un poco de ti, poder mejorar tu perfil y que puedas iniciar el proceso.</p>
        )}
      </div>
      <div className="ProfileResult-container__body">
        <div>
          {profileType.process ? (
            <Calendly
              minWidth="100%"
              height="475px"
              url="https://calendly.com/info-linkinternational/60min"
            />
          ) : (
            <button type="button" onClick={handleModal} className="Terminar-button">
              Terminar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileResutl;
